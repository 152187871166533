const fi = Object.freeze({
  hello: 'Hei',
  shop: 'Verkkokauppa',
  auction: 'Huutokauppa',
  evaluation: 'Arviointi',
  aboutUs: 'Meistä',
  weBuyGold: 'Ostamme kultaa',
  logIn: 'Kirjaudu huutokauppaan',
  email: 'Sähköpostiosoite',
  password: 'Salasana',
  register: 'Rekisteröidy huutokauppaan',
  registerDescription: 'Eikö sinulla ole käyttäjätiliä? Rekisteröidy helposti tästä!',
  createAccount: 'Luo tili',
  confirmPassword: 'Vahvista salasana',
  createAccountDescription:
    'Tervetuloa luomaan MyHolmasto-tili, jonka avulla voit osallistua huutokauppaan ja tehdä siellä ennakkotarjouksia, merkata suosikkikohteita ja seurata omien kohteiden huutotasoa',

  checkEmail: 'Tarkista sähköpostisi',
  checkEmailDescription:
    'Olemme lähettäneet sinulle vahvistusviestin sähköpostiisi. Tarkista myös roskapostit. Mikäli viesti ei saavu sinulle, tarkista sähköpostiosoitteesi ja lähetä viesti uudelleen alla olevaa ”Lähetä uudelleen” -linkkiä painamalla.',
  resend: 'Lähetä uudelleen',
  address: 'Katuosoite',
  zipCode: 'Postinumero',
  country: 'Maa',
  phone: 'Puhelin',
  save: 'TALLENNA',
  userInformationDescription: 'Täytä alla olevat tiedot, voit tehdä tämän myöhemmin kohdasta “tilin tiedot”',
  addressInformationDescription: 'Täytä alla olevat tiedot, voit tehdä tämän myöhemmin kohdasta “tilin tiedot”',
  verifyUserDescription:
    'Pyydämme sinua tunnistautumaan, jotta tunnemme asiakkaamme ja pidämme huutokauppamme luotettavana sekä turvallisena  osto- ja myyntipaikkana.',
  signicatButtonLabel: 'TUNNISTAUDU VERKKOPANKIN KAUTTA',
  creditCardButtonLabel: 'TUNNISTAUDU LUOTTOKORTILLA',
  or: 'TAI',
  welcomeToHolmsato: 'Tervetuloa MyHolmastoon!',
  welcomeToHolmastoDescription:
    'Kiitos rekisteröinnistä! Jotta voit tehdä tarjouksia huutokaupassa tulee sinun tunnistautua ja ilmoittaa tarvittavat yhteystiedot. Voit myös ilmoittaa tilinumerosi jos odotat meiltä tilityksiä.',
  fillTheInformation: 'TÄYTÄ TIEDOT',
  editInformation: 'MUOKKAA TIETOJA',
  accountInfotrmation: 'Tilin tiedot',
  userInformation: 'Käyttäjätiedot',
  cancel: 'Peruuta',
  editAccountInformation: 'Muokkaa tilin tietoja',
  name: 'Etunimi',
  lastName: 'Sukunimi',
  secondHighestBid: 'Voittava tarjous',
  winningBid: 'Voittava tarjous',
  yourBidIsWinning: 'Tarjouksesi ({{ amount }}€) on voittava',
  minimumBidForItemIs: 'Kohteen tämän hetkinen minimikorotus on {{minimumBid}}€',
  bidButtonLabel: 'TARJOA {{minimumBid}}€',
  bitTextInputPlaceholder: 'Määrittele itse korotus, joka on vähintään {{minimumBid}}€',
  bid: 'TARJOA',
  askMoreAboutTheProduct: 'KYSY LISÄÄ TUOTTEESTA',
  preBidStart: 'Ennakkotarjouksia voi jättää {{date}} {{time}} alkaen',
  preBidUntil: 'Ennakkotarjousvaihe sulkeutuu {{date}} klo {{time}}',
  auctionDate: 'Online live-huutokauppa alkaa {{date}} klo {{time}}',
  category: 'Kategoria',
  condition: 'Kunto',
  material: 'Materiaali',
  ruler: 'Hallitsija',
  listOfPriorOffers: 'LUETTELO JA ENNAKKOTARJOUKSET',
  offers: '{{bidsCount}} TARJOUSTA',
  search: 'Etsi',
  addToWishList: 'Lisää suosikkeihin',
  removeFromWishlist: 'Poista suosikeista',
  minimumPrice: 'Lähtöhinta',
  wishlist: 'Suosikit',
  verifyAccountToBidMoreThenAmount: 'Tunnistaudu vahvasti jotta voit tarjota enemmän kuin {{amount}} euroa ',
  sendNotificationWhenYourBiddingNotWinning: 'Ilmoita kun huutoni ei enää ole voittava',
  informationWasUpdatedSuccesfully: 'Tieto päivitetty',
  startingBid: 'Lähtöhinta',
  timeToMakeOffer: 'Aikaa tehdä tarjous',
  nSeconds: '{{n}} sek',
  pleaseLogInToProceed: 'Kirjaudu jatkaaksesi',
  wrongCredentials: 'Sähköpostiosoite tai salasana on väärä',
  required: 'Tämä on pakollinen kenttä',
  shouldBeEmail: 'Tämä pitää olla sähköpostiosoite',
  shouldBePhone: 'Tämä pitää olla puhelinnumero',
  downloadCatalogue: 'TULOSTA LUETTELO',
  liveAuctionHasEndedAt: [
    'Huutokauppa on päättynyt {{ date }}',
    'Siirry maksamaan voitetut kohteet sekä tilaa kuljetus MyHolmasto – omaan profiiliisi. Lähetämme yhteenvedon voitetuista kohteista sähköpostiisi.',
    'Kiitos osallistumisesta! Ystävällisesti, Holmasto',
  ],
  youMayBeInterested: 'Voisit olla kiinnostunut näistä kohteista',
  auctionInProgress: 'Huutokauppa käynnissä',
  youHaveWonNProducts: 'Olet voittanut {{ n }} kohdetta, jatka kassalle',
  minimumAmount: 'lähtöhinta {{ amount }}€ ',
  finalAmount: 'Loppusumma {{ amount }}€ ',
  accept: 'Hyväksyn',
  descline: 'En hyväksy',
  myProducts: 'Tuotteeni',
  orderId: 'Tilausnumero',
  orderStatus: 'Tila',
  orderDate: 'Tilauspäivämäärä',
  orders: 'Voitetut kohteet',
  linkWasSentTo: 'Palautuslinkki lähetetty osoitteeseen {{ email }} ',
  resetPassword: 'Aseta uusi salasana',
  sendResetPasswordLink: 'Lähetä uuden salasanan asetuslinkki',
  verificationFailedTryAgain: 'Vahvistaminen epäonnistui, kokeile uudelleen',
  netsButtonLabel: 'Tunnistaudu NETS:lla',
  creditCardVerify: 'Tunnistaudu luottokortilla',
  clearAll: 'Tyhjää kaikki',
  fileCantBeUploadedTypes: '{{ file }} Ei voida ladata, sallitut tiedostomuodot: {{ types }}',
  youCantUploadMoreThenNFiles: 'Voit ladata vain {{ n }} tiedostoa',
  uploadYourIdDocument: 'Lataa henkilökorttisi',
  dropFilesHere: 'Pudota tiedostot tähän',
  youNeedToAcceptTos: 'Sinun pitää hyväksyä käyttöehdot',
  acceptTosText: 'Hyväksyn käyttöehdot',
  notAvailable: 'Ei käytettävissä',
  previousAuctionN: 'Edellinen huutokauppa {{ n }} ',
  moreProducts: 'Lisää kohteita',
  sold: 'Myyty',
  notSold: 'Ei myyty',
  waiting: 'Odottaa',
  current: 'Tämänhetkinen',
  finalPrice: 'Loppuhinta',
  countries: 'Maat',
  attachedCard: 'Liitteenä kortti',
  lastDigits: 'Viimeiset numerot',
  expirationDate: 'Voimassaolo loppuu',
  reference: 'Referenssi',
  conditionDescription: 'Kuntokuvaus',
  weight: 'Paino',
  size: 'Koko',
  accountIsBlocked: 'Tilisi on lukittu',
  firstName: 'Etunimi',
  state: 'Osavaltio',
  city: 'Postitoimipaikka',
  additionalAddress: 'lisäosoite',
  filters: 'Filtteri',
  payForItems: 'Maksa voitetut kohteesi',
  payForItemsAndShipping: 'Maksa voitetut kohteesi sekä toimituskulut',
  checkoutShipping: 'Kassalle, vain toimituskulut',
  checkoutOrder: 'Kassalle, vain voitetut kohteet',
  shippingMethod: 'Toimituksen valinta',
  noShippingMethod: 'Ei toimitusta',
  startingPrice: 'Lähtöhinta',
  currentHighestBid: 'Voittava huuto',
  youHaveNotBidded: 'Sinulla ei ole huutoja',
  yourBidIsntWinning: 'Huutosi ({{ amount }}) ei ole korkein',
  shipping: 'Toimitus',
  shippingStatus: 'Kuljetuksen tila',
  decideLater: 'Tilaa toimitus myöhemmin',
  allCategories: 'Kaikki kategoriat',
  yourRequestIsBeingProcessed: 'Pyyntösi käsitellään, tämä kestää yleensä työpäivän',
  pickUpFromStore: 'Nouto liikkeestä',
  seeAllProducts: 'KATSO KAIKKI TUOTTEET',
  verifyAccountToBid: 'Ole hyvä ja vahvista tilisi voidaksesi tarjota. Tämän voit tehdä omilla sivuillasi',
  shippingIsSameAsBilling: 'Toimitusosoite on sama kuin laskutusosoite',
  addToWishlist: 'Lisää suosikkeihin',
  billingAddress: 'Laskutusosoite',
  shippingAddress: 'Toimitusosoite',
  documents: 'Asiakirjat',
  notifications: 'Ilmoitukset',
  myDestinations: 'Myyntikohteeni',
  auctionBids: 'Tarjoukseni',
  profile: 'Profiilisi',
  general: 'Yleistä',
  catalogueAndPrebids: 'Luettelo ja ennakkotarjoaminen',
  holmastoAuction: 'Holmasto Huutokauppa',
  articles: 'Kohteet',
  tags: 'Tunniste',
  amount: 'Summa',
  bidDate: 'Tarjous jätettiin {{date}} {{time}}',
  bidding: 'Tarjoaminen',
  bids: 'Huudot',
  creditCard: 'Luottokortti',
  creditCardDescription: 'Tunnistaudu luottokortilla',
  liveBidType: 'Livehuuto',
  manualApproval: 'Manuaalinen vahvistamispyyntö',
  manualApprovalDescription: 'Holmasto käsittelee pyyntösi manuaalisesti, valmistuu yleensä 2-5 päivän sisällä',
  onlineBankCredentials: 'Tunnistautuminen pankkitunnuksilla',
  onlineBankCredentialsDescription: 'Helpoin tapa tunnistautua Suomessa',
  preBidType: 'Ennakkotarjous',
  youDontHaveBid: 'Et ole jättänyt tarjousta',
  yourBid: 'Edellinen tarjouksesi',
  youreNotWinning: 'Tarjouksesi ei ole voittava',
  maxBiddingLevel: 'Sinut on hyväksytty tarjoamaan maks',
  english: 'Englanti',
  finnish: 'Suomi',
  swedish: 'Ruotsi',
  language: 'Kieli',
  getPrice: 'HAE HINTAA',
  openProduct: 'Avaa',
  youMightBeInterestedInThose: 'Voisit olla kiinnostunut näistä kohteista ',
  requestIncrease: 'Pyydän huutorajani nostoa',
  pleaseLoginOrRegisterToBid: 'Kirjaudu tai rekisteröidy jotta voit huutaa',
  itemIsntAvailable: 'Kohde on poistettu',
  welcome: [
    {
      title: 'Kiitos, kun rekisteröidyit!',
      body: 'Tervetuloa Holmaston huutokauppaan ja MyHolmastoon -omille sivuillesi. ',
    },
    {
      title: 'Teet ennakkotarjouksia sinua kiinnostaviin kohteisiin.',
      body: 'Luettelo julkaistaan 3 viikkoa ennen live-huutokauppapäivää. Voit tehdä ennakkotarjouksia aina huutokauppapäivään klo 10.00 asti.',
    },
    {
      title: 'Seuraat omia suosikkikohteitasi.',
      body: 'Merkitse kohteita suosikeiksi ja kokoat ne helposti, yhdeksi kokonaisuudeksi omille sivuillesi.',
    },
    {
      title: 'Saat tietoa ennakkohuutojesi tasosta sekä omien kohteidesi tarjouksista.',
      body: 'Omilta sivuiltasi löydät listan kaikista kohteista, joihin olet antanut ennakkotarjouksen. Seuraat tarjoustesi tasoa ja saat lisäksi tiedon sähköpostiisi  jos tekemäsi ennakkotarjous on ylittynyt. ',
    },
    {
      title: 'Voit osallistua Live-huutokauppaan.',
      body: 'Kirjautuneena käyttäjänä voit osallistua live-huutokauppohin, joita Holmasto järjestää  neljä kertaa vuodessa (maalis-, touko-, loka- ja joulukuu).',
    },
    {
      title: 'Tervetuloa, palvelemme sinua mielellämme kaikissa huutokauppaan liittyvissä asioissa ja kysymyksissä. ',
      body: '- Holmasto',
    },
  ],
  proceedToAuthenticate: 'Jatka tunnistaumiseen',
  attachCard: 'Lisää kortti',
  submit: 'Lisää',
  ascAboutShippingCost: 'Kysy kuljetuksen kustannus',
  sendNotificationWhenYourBiddingIsWinning: 'Lähetä viesti kun tarjoukseni on voittava',
  logOut: 'kirjaudu ulos',
  netvisorInvoice: 'lasku (maksuaika 7 päivää)',
  compensated: 'Kompensoitu',
  yourItemHsBeenCompensated: 'Kohteesi on maksettu tilillesi',
  seller: 'Myyjä',
  iAmSeller: 'Olen myyjä',
  accountNumber: 'Tilinumeroni',
  proposals: 'Ehdotukset',
  reject: 'hylkää',
  approve: 'Hyväksy',
  approved: 'hyväksytty',
  rejected: 'hylätty',
  youWon: 'Voitit kohteen! <0 /> Onneksi olkoon!',
  youDidNotWin: 'Et voittanut tätä kohdetta',
  liveAuctionStartsIn: 'Live-huutokaupan alkuun',
  goToLobby: 'Siirry odotustilaan',
  liveAuctionIsHappeningNow: 'Live-huutokauppa on aktiivinen',
  goToLiveAuction: 'Siirry huutamaan',
  LiveAuctionWillStartIn: 'Live-huutokauppa alkaa {{ min }} minuutin kuluttua',
  withCommission: 'Sis. provisio',
  commission: 'Provisio',
  winningPrice: 'Kohteet',
  totalPrice: 'Kokonaishinta',
  totalSum: 'Kokonaissumma',
  products: 'Kohteet',
  catalogue: 'Luettelo',
  netvisorShippingInvoice: 'Toimituksen lasku',
  lotNumber: 'Kohdenumero',
  checkout: 'Maksamaan',
  participate: 'Osallistu',
  invoice: 'Lasku',
  passwordMustBeSame: 'Salasana ei täsmää',
  passwordShouldBeMinNumbers: 'Salasanan tulee olla vähintään {{ min }} merkkiä',
  emailShouldBeUnique: 'Tämä sähköpostiosoite on jo rekisteröity meillä',
  invalidZipCode: 'Väärä postinumero',
  start: 'Aloitus',
  basic: 'Perustiedot',
  identification: 'Tunnistus',
  identificationHeading: 'Tunnistautuminen',
  ready: 'Valmis',
  next: 'SEURAAVA',
  attachPhotoDescription: 'Liitä kuva passistasi tai henkilökortistasi, jotta voimme edetä tunnistautumisessa.',
  ifYouWantToSpeedUpText:
    'jos haluat nopeuttaa tunnistautumista, anna luottokorttisi tiedot. Näin voimme varmistua, että tiedot täsmäävät ja tunnistus nopeutuu. Emme veloita kortistasi mitään.',
  creditCardSpeedUpDesc: 'Haluan nopeuttaa käsittelyä tunnistautumalla luottokortilla (ei veloiteta)',
  noCardDesc: 'En halua antaa luottokorttitietojani',
  documentSent: 'Dokumentti lähetetty',
  documentSentDescription:
    'olemme vastaanottaneet pyyntösi! käsittelemme pyyntösi 2-5 päivän kuluessa. jos haluat vaihtaa tunnistautumistapaa, ota yhteyttä auctions@holmasto.fi',
  everythingIsReady: 'Kaikki on valmista!',
  nowYouCanUseAllTheFeatures: 'Nyt voit käyttää kaikkia ominaisuuksia.',
  toHomePage: 'ETUSIVULLE',
  whenYouIdentifyDescription:
    'Kun tunnistaudut verkkopankin kautta tai luottokortilla, pääset käyttämään kaikkia ominaisuuksia ilman odotusaikaa.',
  whenYouIdentifyManualDescription:
    'Kun tunnistaudut manuaalisesti, et pääse heti käyttämään ominaisuuksia. Odotusaika 2-5 päivää',
  logInToTheOnlineStore: 'Kirjaudu verkkokauppaan',
  logInToTheOnlineAuction: 'Kirjaudu huutokauppaan',
  youDontHaveBidsForThisAuction: 'Sinulla ei ole huutoja tässä huutokaupassa',
  generateLastAuctionPdf: 'Edellisen huutokaupan tulokset',
  nextItem: 'SEURAAVA KOHDE',
  prevItem: 'EDELLINEN KOHDE',
  requestShipping: 'Tilaa toimitus',
  itemHasClosed: 'Kohde on sulkeutunut',
  sorry: 'Pahoittelut',
  pageWasntFound: 'etsimääsi sivua ei löytynyt',
  GoToHomePage: 'PALAA ETUSIVULLE',
  hammerPrice: 'Vasarahinta',
  tokenExpired: 'Vanhentunut linkki',
  linkNotValid: 'Linkki ei ole enää voimassa',
  youApprovedReception: 'Kiitos, olet hyväksynyt tarjouksemme!',
  weWillSendYouEmail: 'Myyntisopimus toimitetaan sähköpostitse.',
  youApprovedProducts: 'Kiitos, olet hyväksynyt kohteesi myytäväksi huutokaupassamme.',
  youApprovedProductsDescription: `
    Suosittelemme, että kirjaudut/rekisteröidyt Holmaston MyHolmasto, omille sivuillesi. MyHolmastossa voit hyväksyä/hylätä kohteitasi koskevia ostotarjouksia ja huutokaupan myyntisopimuksia. 
    Seuraat omia kohteitasi huutokaupassa ja saat tietoa ennakkotarjoustasosta sekä huutokaupan päätyttyä saatavillasi on lista alustavista huutokauppatuloksista
  `,
  goToMyHolmasto: 'Siirry MyHolmastoon',
  goToFrontPage: 'Siirry etusivulle',
  emailWasChanged: 'Sähköpostiosoite päivitetty',
  youHaveCanceledPayment: 'You have cancelled the payment (fi)',
  tokenWasExpiredTryAgain: 'Token was exzpired try again (fi)',
  passwordWasChanged: 'Password was changed (fi)',
  companyName: 'Yrityksen nimi',
  setPassword: 'Aseta salasana',
  begin: 'Aloita',
  myProfile: 'Profiilini',
  webshop: 'Verkkokauppa',
  auctionCatalogue: 'Huutokauppaluettelo',
  youCanEnterAccountNumber: 'Tässä voit ilmoittaa tilinumerosi, jos odotat meiltä tilitystä',
  priceWasRequested: 'Shipping price was requested (fi)',
  frontPage: 'Etusivu',
  awaitingContactFromHolmasto: 'Awaiting contact from Holmasto (fi)',
  clickHereToRequestNewLink: 'Click <a>here</a> to request new link (fi)',
  pleaseFillBid: 'Please fill bid (fi)',
  bidShouldBeMoreThenAmount: 'Bid should be more then {{minimumBid}} € (fi)',
  canNotBeDelivered: 'Ei voida toimittaa',
  pending: 'Odottaa maksua',
  processing: 'Käsittelyssä',
  onHold: 'Pidossa',
  completed: 'Valmistunut',
  cancelled: 'Peruttu',
  refunded: 'Hyvitetty',
  failed: 'Epäonnistui',
  checkoutDraft: 'Luonnos',
  cookieSettings: 'EVÄSTEASETUKSET',
  cookieDescription: `
    Verkkosivumme käyttää välttämättömiä evästeitä, jotta sivut toimivat ja kokemuksesi olisi vieläkin makeampi. Voit lukea lisää 
    käyttämistämme evästeistä ja hallita asetuksiasi evästeasetussivulla. You can read more about them use and control their settings.
  `,
  necessary: 'VÄLTTÄMÄTTÖMÄT',
  statistics: 'TILASTOT',
  marketing: 'MARKKINOINTI',
  necessaryCookieDescription: `
    Nämä evästeet ovat välttämättömiä, jotta verkkosivumme toimisi oikein, esimerkiksi suojaustoiminnot tai evästeiden suostumusasetukset.
  `,
  statisticsCookieDescription: `
    Parantaaksemme verkkosivuamme keräämme nimettömästi tietoja tilastollisiin ja analyyttisiin tarkoituksiin. Näiden evästeiden 
    avulla voimme esimerkiksi seurata verkkosivumme tiettyjen sivujen käyntien määrää tai kestoa, mikä auttaa meitä optimoimaan 
    käyttäjäkokemusta.
  `,
  marketingCookieDescription: 'Nämä evästeet auttavat meitä mittaamaan ja optimoimaan markkinointitoimiamme.',
  approveAll: 'HYVÄKSY KAIKKI',
  approveChosen: 'HYVÄKSY VALITUT',
  editCookies: 'MUOKKAA EVÄSTEITÄ',
  yourPreBidIsWinning: 'Du har det vinnande budet ({{ amount }}€)',
  preBidHighestPrice: 'Korkein ennakkohuuto',
  currentItems: 'Menossa olevat kohteet',
  upcomingAuction: 'Seuraava huutokauppa',
  localPickup: 'Nouto liikkeestä',
})

export default fi
