const en = Object.freeze({
  hello: 'Hello',
  shop: 'Shop',
  auction: 'Auction',
  evaluation: 'Evaluation',
  aboutUs: 'About Us',
  weBuyGold: 'We Buy Gold',
  logIn: 'Login to auction',
  email: 'Email',
  password: 'Password',
  register: 'Register to auction',
  registerDescription: "If you're not registered yet, It's easy, just a few clicks",
  createAccount: 'Create Account',
  confirmPassword: 'Confirm password',
  createAccountDescription:
    'Welcome to create a MyHolmasto account where you can leave pre-bids or participate in the live auctions',
  checkEmail: 'Please check your email',
  checkEmailDescription:
    'We have sent you a confirmation message to your email. Also check your spam. If the message does not reach you, check your email address and resend the message by pressing the "Resend" link below.',
  resend: 'Resend',
  address: 'Address',
  zipCode: 'Zip Code',
  country: 'Country',
  phone: 'Phone',
  save: 'Save',
  userInformationDescription: 'Please fill in the form, You can also do it later in the section “Account info”',
  addressInformationDescription: 'Please fill in the form, You can also do it later in the section “Account info”',
  verifyUserDescription:
    'We ask you to identify yourself so that we know our customers and consider our auction as a reliable and safe place to buy and sell.',
  signicatButtonLabel: 'IDENTIFY YOURSELF WITH BANKCODES',
  creditCardButtonLabel: 'IDENTIFY YOURSELF WITH CREDIT CARD',
  or: 'or',
  welcomeToHolmsato: 'Welcome to MyHolmastoon!',
  welcomeToHolmastoDescription:
    'Thank you for registering! In order to bid in the auction, you must identify yourself and provide the necessary contact information. You can also state your account number if you expect transaktions from us.',
  fillTheInformation: 'FILL IN THE INFO',
  editInformation: 'EDIT INFO',
  accountInfotrmation: 'Account info',
  userInformation: 'User info',
  cancel: 'Cancel',
  editAccountInformation: 'Edit account info',
  name: 'Name',
  lastName: 'Last name',
  secondHighestBid: 'Winning bid',
  winningBid: 'Winning Bid',
  yourBidIsWinning: 'Your bid ({{ amount }}€) is winning',
  minimumBidForItemIs: 'Minimum bid for item is {{minimumBid}}€',
  bidButtonLabel: 'Bid {{minimumBid}}€',
  bitTextInputPlaceholder: 'Amount should be more than {{minimumBid}}€',
  bid: 'Bid',
  askMoreAboutTheProduct: 'Ask more about this item',
  preBidStart: 'Pre bidding starts {{date}} {{time}}',
  preBidUntil: 'Pre bidding ends {{date}} {{time}}',
  auctionDate: 'Online live auction starts {{date}} {{time}}',
  category: 'Category',
  condition: 'Condition',
  material: 'Material',
  ruler: 'Ruler',
  listOfPriorOffers: 'List of prior offers',
  offers: '{{bidsCount}} Bids',
  search: 'Search',
  addToWishList: 'Add to favourites',
  removeFromWishlist: 'Remove from favourites',
  minimumPrice: 'Minimum Price',
  wishlist: 'Favourites',
  verifyAccountToBidMoreThenAmount: "You can't bid more than {{amount}} euro",
  sendNotificationWhenYourBiddingNotWinning: 'Send notification when your bid is no longer winning',
  informationWasUpdatedSuccesfully: 'Information was updated',
  startingBid: 'Start Bid',
  timeToMakeOffer: 'Time to make bid',
  nSeconds: '{{n}} Seconds',
  pleaseLogInToProceed: 'Please login to proceed',
  wrongCredentials: 'Email or password is incorrect',
  required: 'This field is required',
  shouldBeEmail: 'This field must be email',
  shouldBePhone: 'This field must be phone',
  downloadCatalogue: 'Download catalogue',
  liveAuctionHasEndedAt: [
    'The auction has ended {{ date }}',
    'Go to MyHolmasto - your own profile to order transport and to make the payment. A summary of the items you won will be sent to your email.',
    'Thank you for participating! Best Regards, Holmasto.',
  ],
  youMayBeInterested: 'You may be interested in these items',
  auctionInProgress: 'Auction in progress',
  youHaveWonNProducts: 'You have won {{ n }} products proceed to checkout',
  minimumAmount: 'Minimum amount {{ amount }}€',
  finalAmount: 'Final amount {{ amount }}€',
  accept: 'Accept',
  descline: 'Decline',
  myProducts: 'My products',
  orderId: 'Order Id',
  orderStatus: 'Order Status',
  orderDate: 'Order Date',
  orders: 'Won items',
  yourBid: 'Your last Bid',
  youreNotWinning: "You're not winning",
  youDontHaveBid: 'You are not bidding on this item',
  linkWasSentTo: 'Recovery link was sent to {{ email }}',
  resetPassword: 'Reset Password',
  sendResetPasswordLink: 'Send Reset password link',
  verificationFailedTryAgain: 'Verification failed please try again',
  netsButtonLabel: 'Authenticate using nets',
  creditCardVerify: 'Authenticate using credit card',
  clearAll: 'Clear All',
  fileCantBeUploadedTypes: "{{ file }} Can't be uploaded, allowed types are: {{ types }}",
  youCantUploadMoreThenNFiles: "You can't upload more than {{ n }} files",
  uploadYourIdDocument: 'Upload you ID',
  dropFilesHere: 'Drop files here',
  youNeedToAcceptTos: 'You need to accept terms of service',
  acceptTosText: 'I accept terms of service',
  amount: 'Amount',
  bids: 'Bids',
  bidding: 'Bidding',
  bidDate: 'Bid was placed on {{date}} {{time}}',
  preBidType: 'Prebid',
  liveBidType: 'Livebid',
  notAvailable: 'Not available',
  previousAuctionN: 'Previous Auction {{ n }}',
  moreProducts: 'More Products',
  sold: 'Sold',
  notSold: 'Not Sold',
  waiting: 'Waiting',
  current: 'Current',
  finalPrice: 'Final price',
  countries: 'Countries',
  attachedCard: 'Attached Card',
  lastDigits: 'Last Digits',
  expirationDate: 'Expiration Date',
  reference: 'Reference',
  conditionDescription: 'Condition Description',
  weight: 'Weight',
  size: 'Size',
  accountIsBlocked: 'Account is blocked',
  firstName: 'First Name',
  state: 'State',
  city: 'City',
  additionalAddress: 'Additional Address',
  filters: 'Filters',
  payForItems: 'Pay for items',
  payForItemsAndShipping: 'Pay for items and shipping',
  checkoutShipping: 'Checkout shipping',
  onlineBankCredentials: 'Online bank credentials',
  creditCard: 'Credit card',
  manualApproval: 'Manual approval request',
  onlineBankCredentialsDescription: 'Fastest way to authenticate, supports Finnish banks',
  creditCardDescription: 'Authenticate using your credit card',
  manualApprovalDescription: 'Holmasto will manually review your request, takes 2-5 days',
  checkoutOrder: 'Checkout Order',
  shippingMethod: 'Shipping Method',
  noShippingMethod: 'No shipping',
  startingPrice: 'Starting price',
  currentHighestBid: 'Current highest bid',
  youHaveNotBidded: "You haven't bidded yet",
  yourBidIsntWinning: "Your bid ({{ amount }}) isn't winning",
  shipping: 'Shipping',
  shippingStatus: 'Shipping status',
  decideLater: 'Decide later',
  allCategories: 'All Categories',
  yourRequestIsBeingProcessed: 'Your request is being processed, this usually takes no more than one working day',
  pickUpFromStore: 'Pickup from store',
  seeAllProducts: 'See all products',
  verifyAccountToBid: 'Please verify your account to bid',
  shippingIsSameAsBilling: 'Shipping address is same as billing address',
  addToWishlist: 'Add to Favourites',
  billingAddress: 'Billing Address',
  shippingAddress: 'Shipping Address',
  documents: 'Documents',
  notifications: 'Notifications',
  myDestinations: 'My items for sale',
  auctionBids: 'Auction Bids',
  profile: 'Profile',
  general: 'General',
  catalogueAndPrebids: 'Catalogue and pre-bids',
  holmastoAuction: 'Holmasto Auction',
  articles: 'Articles',
  tags: 'Tags',
  maxBiddingLevel: 'Max bidding level',
  english: 'English',
  finnish: 'Finnish',
  swedish: 'Swedish',
  language: 'Language',
  getPrice: 'GET PRICE',
  openProduct: 'Open',
  youMightBeInterestedInThose: 'You might be interested in those items',
  requestIncrease: 'Request higher bid clearence ',
  pleaseLoginOrRegisterToBid: 'Please login or register in order to place a bid',
  itemIsntAvailable: "Item isn't available",
  welcome: [
    {
      title: 'Thank you for registering!',
      body: 'Welcome to the Holmasto auction site and to your MyHolmasto pages',
    },
    {
      title: 'You may now make pre-bids for items that interest you.',
      body: 'The auction catalogue will be published 3 weeks before the live auction date. You can make pre-bids until 10:00 a.m. on the auction day.',
    },
    {
      title: 'You can follow your own favorite items.',
      body: 'Mark items as favorites and you can easily collect them into a single entity on your own pages.',
    },
    {
      title:
        'You will receive information about the level of your pre-bids and also bidding level of your own items on sale.',
      body: 'On your own pages, you can find a list of all the items for which you have made pre-bids. You will monitor the level of your bids and you will also receive a notification to your email if your bid has been exceeded.',
    },
    {
      title: 'You can participate in the Live auction..',
      body: 'As a logged-in user, you can participate in live auctions that Holmasto organizes four times a year (March, May, October and December).',
    },
    {
      title: 'Welcome, we will be happy to serve you in all matters and questions related to the auction.',
      body: ' - Holmasto',
    },
  ],

  proceedToAuthenticate: 'Proceed to authenticate',
  attachCard: 'Attach Card',
  submit: 'Submit',
  ascAboutShippingCost: 'Ask about shipping cost',
  sendNotificationWhenYourBiddingIsWinning: 'Send notification when your bid is winning',
  logOut: 'Log out',
  netvisorInvoice: 'Netvisor Invoice',
  yourItemHsBeenCompensated: 'Your item has been Compensated',
  seller: 'Seller',
  iAmSeller: 'I am seller',
  accountNumber: 'My account number',
  proposals: 'Proposals',
  approve: 'Approve',
  reject: 'Reject',
  approved: 'Approved',
  rejected: 'Rejected',
  youWon: 'You won the item! <0 />  Congratulations!',
  youDidNotWin: "You didn't win",
  liveAuctionStartsIn: 'Live auction starts in',
  goToLobby: 'Go to waiting room',
  liveAuctionIsHappeningNow: 'Live Auction is active',
  goToLiveAuction: 'Start bidding',
  LiveAuctionWillStartIn: 'Live auction will start in {{ min }}',
  withCommission: 'With commission',
  commission: 'Commission',
  winningPrice: 'Winning price',
  totalPrice: 'Total price',
  totalSum: 'Total sum',
  products: 'Items',
  catalogue: 'Catalogue',
  netvisorShippingInvoice: 'Netvisor shipping invoice',
  lotNumber: 'Lot number',
  checkout: 'To checkout',
  participate: 'Participate',
  invoice: 'Invoice',
  yourPreBidIsWinning: 'Your prebid ({{ amount }}€) is winning',
  yourPreBidIsNotWinning: 'Your prebid ({{ amount }}€) is not winning',
  liveAuctionWinningPrice: 'Live auction winning price',
  passwordMustBeSame: 'Password must be same',
  passwordShouldBeMinNumbers: 'Password should be minimum {{ min }} charcters long',
  emailShouldBeUnique: 'Email is already registered',
  invalidZipCode: 'Invalid zip code',
  start: 'Start',
  basic: 'Basic',
  identification: 'Identification',
  identificationHeading: 'Identification',
  ready: 'Ready',
  next: 'Next',
  attachPhotoDescription:
    'Attach photo of passport data sheet (all non-EU citizens) or European ID-card, This way we can proceed with the identification',
  ifYouWantToSpeedUpText:
    'If you want to speed up the id-process, please provide you credit card info.This helps us to do a proper identification. Nothing will be charged from your card',
  creditCardSpeedUpDesc: 'I want to speed up the process by providing my credit card info.',
  noCardDesc: 'I do not want to provide credit card info',
  documentSent: 'Document was sent',
  documentSentDescription:
    'Your request is received! You will hear from us within 2-5 days. If you have any questions concerning speeding up the process please contact auctions@holmasto.fi',
  everythingIsReady: 'Everything is ready',
  nowYouCanUseAllTheFeatures: 'Now you can use all of the features',
  toHomePage: 'Go to homepage',
  whenYouIdentifyDescription:
    'When identifying thru your web-bank or credit card, you will have all the features ready for use (EU-citizens)',
  whenYouIdentifyManualDescription:
    'When requesting manual identification, you will not get immidiate access to all features,(This also applies to all non-EU registrations)',
  logInToTheOnlineStore: 'Login to webshop',
  logInToTheOnlineAuction: 'Login to auction',
  youDontHaveBidsForThisAuction: "You don't have bids for this auction",
  generateLastAuctionPdf: 'Generate last auction pdf',
  nextItem: 'Next Item',
  prevItem: 'Previous Item',
  requestShipping: 'Request shipping',
  itemHasClosed: 'Lot has closed',
  sorry: 'Sorry',
  pageWasntFound: "Page wasn't found",
  GoToHomePage: 'Go to home page',
  hammerPrice: 'Hammer price',
  tokenExpired: 'Expired link',
  linkNotValid: 'The link is no longer valid',
  youApprovedReception: 'Thank you, you have accepted our offer!',
  weWillSendYouEmail: 'The sales contract is delivered by email.',
  youApprovedProducts: 'Thank you! You have approved your objects to be sold on our auction',
  youApprovedProductsDescription: `
    We recommend that you log in/register on Holmasto’s MyHolmasto, your own pages. In MyHolmasto, you can accept/reject purchase  offers and auction sales contracts for your items. 
    You follow your own items in the auction and get information about the pre-bid level, and after the auction ends, you have a list of preliminary auction results.
  `,
  goToMyHolmasto: 'Go to MyHolmasto',
  goToFrontPage: 'Go to front page',
  emailWasChanged: 'Email was changed',
  youHaveCanceledPayment: 'You have cancelled the payment',
  tokenWasExpiredTryAgain: 'Token was exzpired try again',
  passwordWasChanged: 'Password was changed',
  companyName: 'Company name',
  setPassword: 'Set password',
  begin: 'Begin',
  myProfile: 'My profile',
  webshop: 'Webshop',
  auctionCatalogue: 'Auction catalogue',
  youCanEnterAccountNumber: 'Here you can enter your account number if you expect a transaction from us',
  priceWasRequested: 'Shipping price was requested',
  frontPage: 'Front page',
  awaitingContactFromHolmasto: 'Awaiting contact from Holmasto',
  clickHereToRequestNewLink: 'Click <link> here </link> to request new link (en)',
  pleaseFillBid: 'Please fill bid',
  bidShouldBeMoreThenAmount: 'Bid should be more then {{minimumBid}} €',
  canNotBeDelivered: 'Cannot be delivered',
  pending: 'Pending',
  processing: 'Processing',
  onHold: 'Om Hold',
  completed: 'Completed',
  cancelled: 'Cancelled',
  refunded: 'Refunded',
  failed: 'Failed',
  checkoutDraft: 'Checkout Draft',
  cookieSettings: 'Cookie settings',
  cookieDescription: `
    Vår webbplats använder cookies för att webbplatsen ska fungera korrekt och för att din användarupplevelse ska 
    bli ännu bättre. Du kan läsa mer om dem samt konfigurera dina inställningar
  `,
  necessary: 'NECESSARY',
  statistics: 'STATISTICS',
  marketing: 'MARKETING',
  necessaryCookieDescription: `
    These cookies are technically required for our core website to work properly, e.g. security functions 
    or your cookie consent preferences.
  `,
  statisticsCookieDescription: `
    In order to improve our website going forward, we anonymously collect data for statistical and analytical 
    purposes. With these cookies we can, for instance, monitor the number or duration of visits of specific pages 
    of our website helping us in optimizing user experience.
  `,
  marketingCookieDescription: 'These cookies help us in measuring and optimizing our marketing efforts.',
  approveAll: 'Approve All',
  approveChosen: 'Approve Chosen',
  editCookies: 'Edit cookies',
  preBidHighestPrice: 'Highest prebid',
  currentItems: 'Current items',
  upcomingAuction: 'Upcoming auction',
  localPickup: 'Local pickup',
})

export default en
