const sv = Object.freeze({
  hello: 'Hej',
  shop: 'Butik',
  auction: 'Auktion',
  evaluation: 'Utvärdering',
  aboutUs: 'Om oss',
  weBuyGold: 'Vi köper guld',
  logIn: 'Logga in till auktionen',
  email: 'Email',
  password: 'Lösenord',
  register: 'Registrera till auktionen',
  registerDescription: 'Vill du lätt skapa ett Holmasto konto? ',
  createAccount: 'Skapa konto',
  confirmPassword: 'Bekräfta lösenordet',
  createAccountDescription:
    'Välkommen att skapa ett Holmasto-konto. Där kan du bl.a.' + ' ge skriftliga bud samt deltaga i live-auktioner',
  checkEmail: 'Kolla din email',
  checkEmailDescription:
    'Vi har skickat ett bekräftelsemeddelande till din e-post. Kolla även din spam. Om meddelandet inte når dig, kontrollera din e-postadress och skicka meddelandet igen genom att klicka på länken "Skicka igen" nedan.',
  resend: 'Send på nytt',
  address: 'Gatuadress',
  zipCode: 'Postnummer och stad',
  country: 'Land',
  phone: 'Telefon',
  save: 'SPARA',
  userInformationDescription: 'Fyll i uppgifterna nedan, Du kan även göra det senare i fliken "kontouppgifter”',
  addressInformationDescription: 'Fyll i uppgifterna nedan, Du kan även göra det senare i fliken "kontouppgifter”',
  verifyUserDescription:
    'Vi ber dig att identifiera dig så att vi känner våra kunder och betraktar vår auktion som en pålitlig och säker plats att köpa och sälja.',
  signicatButtonLabel: 'IDENTIFIERA DIG VIA NÄTBANK',
  creditCardButtonLabel: 'IDENTIFIERA DIG MED KREDITKORT',
  or: 'ELLER',
  welcomeToHolmsato: 'Välkommen till MyHolmasto!',
  welcomeToHolmastoDescription:
    'Tack för din registrering! För att ge bud i auktionen måste du identifiera dig och uppge nödvändiga kontaktuppgifter. Du kan även ange ditt kontonummer om du förväntar dig transaktioner från oss.',
  fillTheInformation: 'FYLL I',
  editInformation: 'ÄNDRA',
  accountInfotrmation: 'Kontouppgifter',
  userInformation: 'Användaruppgifter',
  cancel: 'Avsluta kontot',
  editAccountInformation: 'Ändra kontouppgifter',
  name: 'Namn',
  lastName: 'Efternamn',
  secondHighestBid: 'Vinnande bud',
  winningBid: 'Vinnande bud',
  yourBidIsWinning: 'Du har det vinnande budet ({{ amount }}€)',
  minimumBidForItemIs: 'Minsta bud för detta objekt är {{minimumBid}}€',
  bidButtonLabel: 'Bjud {{minimumBid}}€',
  bitTextInputPlaceholder: 'Budet bör vara minst {{minimumBid}}€',
  bid: 'Bjud',
  askMoreAboutTheProduct: 'Fråga mer om detta objekt',
  preBidStart: 'Budgivning börjar {{date}} {{time}}',
  preBidUntil: 'Budgivning slutar {{date}} {{time}} ',
  auctionDate: 'Online live-auktionen börjar {{date}} {{time}}',
  category: 'Kategori',
  condition: 'Skick',
  material: 'Material',
  ruler: 'Härskare',
  listOfPriorOffers: 'KATALOG OCH BUDGIVNING',
  offers: '{{bidsCount}} Bud',
  search: 'Sök',
  addToWishList: 'Lägg till Favoriter',
  removeFromWishlist: 'Ta bort från Favoriter',
  minimumPrice: 'Minimipris',
  wishlist: 'Favoriter',
  verifyAccountToBidMoreThenAmount: 'For att kunna bjuda mer än {{amount}} euro krävs en stark identifiering',
  sendNotificationWhenYourBiddingNotWinning: 'Sänd meddelande då jag blivit överbjuden',
  informationWasUpdatedSuccesfully: 'Informationen uppdaterades',
  startingBid: 'Utgångspris',
  timeToMakeOffer: 'Tid att ge bud',
  nSeconds: '{{n}} sek ',
  pleaseLogInToProceed: 'Logga in för att fortsätta',
  wrongCredentials: 'Email eller lösenord felaktigt',
  required: 'Detta fält är obligatoriskt',
  shouldBeEmail: 'Detta måste vara en email-adress',
  shouldBePhone: 'Detta bör vara en telefonnummer',
  downloadCatalogue: 'SKRIV UT KATALOGEN',
  liveAuctionHasEndedAt: [
    'Auktionen har avslutats {{ date }} ',
    'Gå till MyHolmasto – din egen profil för att betala och beställa transport Vi skickar en sammanfattning av föremålen du vunnit till din e-post.',
    'Stort tack! Med vänlig hälsning, Holmasto  ',
  ],
  youMayBeInterested: 'Intressanta objekt',
  auctionInProgress: 'Auktionen pågår',
  youHaveWonNProducts: 'Du har vunnit {{ n }} objekt. Gå vidare till kassan',
  finalAmount: 'Slutsumma {{ amount }}€ ',
  accept: 'Godkänn',
  descline: 'Avvisa',
  myProducts: 'Mina objekt',
  orderId: 'Beställningsnummer',
  orderStatus: 'Status på beställning',
  orderDate: 'Beställningsdatum',
  orders: 'Vunna objekten',
  linkWasSentTo: 'Länken sändes till {{ email }}',
  resetPassword: 'Ställ om lösenordet',
  sendResetPasswordLink: 'Sänd lösenordets omställningslänk',
  verificationFailedTryAgain: 'Bekräftelsen misslyckades, pröva på nytt',
  netsButtonLabel: 'Autentisering via NETS',
  creditCardVerify: 'Autentisering med kreditkort',
  clearAll: 'Töm allt',
  fileCantBeUploadedTypes: '{{ file }} Filen kan inte laddas, endast dessa typs filer kan laddas: {{ types }}',
  youCantUploadMoreThenNFiles: 'Du kan endast ladda upp {{ n }} st filer',
  uploadYourIdDocument: 'Ladda upp ditt ID-kort eller pass',
  dropFilesHere: 'Droppa fler här',
  youNeedToAcceptTos: 'Du bör godkänna Holmastos användarvillkor',
  acceptTosText: 'Jag godkänner användarvillkoren',
  notAvailable: 'Inte tillgängligt',
  previousAuctionN: 'Föregående auktion {{ n }} ',
  moreProducts: 'Mera objekt',
  sold: 'Såld',
  notSold: 'Osåld',
  waiting: 'Väntar',
  current: 'Nuvarande',
  finalPrice: 'Slutpris',
  countries: 'Länder',
  attachedCard: 'Bifogat kort',
  lastDigits: 'Sista tecknen',
  expirationDate: 'Utgångsdatum',
  reference: 'Referens',
  conditionDescription: 'Beskrivning av skicket',
  weight: 'Vikt',
  size: 'Storlek',
  accountIsBlocked: 'Kontot är spärrat',
  firstName: 'Förnamn',
  state: 'Delstat',
  city: 'Stad',
  additionalAddress: 'Övrig adress',
  filters: 'Filter',
  payForItems: 'Betala objekten',
  payForItemsAndShipping: 'Betala objekt samt transport',
  checkoutShipping: 'Checkout transport',
  checkoutOrder: 'Checkout objekt',
  shippingMethod: 'Transportmetod',
  noShippingMethod: 'Ingen transport',
  startingPrice: 'Startpris',
  currentHighestBid: 'Vinnande bud',
  youHaveNotBidded: 'Du har inte bjudit ännu',
  yourBidIsntWinning: 'Ditt bud ({{ amount }}) är överbjudet',
  shipping: 'Transport',
  shippingStatus: 'Fraktstatus',
  decideLater: 'Beställ transport senare',
  allCategories: 'Alla kategorier',
  yourRequestIsBeingProcessed: 'Din begäran har registrerats, den behandlas vanligtvis under en arbetsdag',
  pickUpFromStore: 'Avhämta från vår butik',
  seeAllProducts: 'Se alla objekt',
  verifyAccountToBid: 'Vänligen verifiera ditt konto för att kunna bjuda i auktionen',
  shippingIsSameAsBilling: 'Leveransadressen är samma som faktureringadressen',
  addToWishlist: 'Lägg till i Favoriter',
  billingAddress: 'Faktureringsadress',
  shippingAddress: 'Leveransadress',
  documents: 'Dokument',
  notifications: 'Meddelanden',
  myDestinations: 'Objekt jag säljer',
  auctionBids: 'Förhandsbud',
  profile: 'Min profil',
  general: 'Allmänt',
  catalogueAndPrebids: 'Till katalogen och förhandsbudgivning',
  holmastoAuction: 'Holmasto Auktion',
  articles: 'Objekt',
  tags: 'Taggar',
  amount: 'Summa',
  bidDate: 'Förhandsbudet lämnades {{date}} {{time}} ',
  bidding: 'Budgivning',
  bids: 'Bud',
  creditCard: 'Kreditkort',
  creditCardDescription: 'Identifiering via kreditkort',
  liveBidType: 'Livebud',
  manualApproval: 'Begär manuell autentisering',
  manualApprovalDescription: 'Holmasto behandlar din begäran inom 2-5 dagar.',
  onlineBankCredentials: 'Bankidentifiering',
  onlineBankCredentialsDescription: 'Snabbaste sättet att identifiera sig. Fungerar med finländska banker',
  preBidType: 'Förhandsbud',
  youDontHaveBid: 'Du bjuder inte på detta objekt',
  yourBid: 'Ditt senaste bud',
  youreNotWinning: 'Du har inte vinnande bud',
  maxBiddingLevel: 'Du är godkänd för bud upp till',
  english: 'Engelska',
  finnish: 'Finska',
  swedish: 'Svenska',
  language: 'Språk',
  getPrice: 'Fråga pris',
  openProduct: 'Öppna',
  youMightBeInterestedInThose: 'Objekt som kan intressera',
  requestIncrease: 'Be om högre budklarering',
  pleaseLoginOrRegisterToBid: 'Vänligen logga in eller registrera dej för att kunna ge bud',
  itemIsntAvailable: 'Objektet har utgått',
  welcome: [
    {
      title: 'Tack för din registrering!',
      body: 'Välkommen till Holmasto-auktionen och till dina MyHolmasto-sidor.',
    },
    {
      title: 'Du kan ge förhandsbud på objekt som intresserar dig.',
      body: 'Katalogen publiceras 3 veckor före auktionsdatumet. Du kan lägga förhandsbud fram till klockan 10.00 på auktionsdagen.',
    },
    {
      title: 'Du följer dina egna favoritmål.',
      body: 'Markera objekt som favoriter och du kan enkelt samla dem på samma ställe på dina egna sidor.',
    },
    {
      title: 'Du kommer att få information om nivån på dina förhandsbud samt budnivån och  på objekt du själv säljer.',
      body: 'På dina egna sidor kan du hitta en lista över alla föremål som du har lagt  förhandsbud på. Du kan att övervaka nivån på dina bud och  få ett meddelande till din e-post om ditt förhandsbud har överskridits.',
    },
    {
      title: 'Du kan delta i liveauktionen.',
      body: 'Som inloggad användare kan du delta i liveauktioner som Holmasto anordnar fyra gånger per år (mars, maj, oktober och december).',
    },
    {
      title: 'Välkommen, vi betjänar dig gärna i alla frågor och frågor som rör auktionen.',
      body: ' - Holmasto',
    },
  ],
  proceedToAuthenticate: 'Fortsätt till autentisering',
  attachCard: 'Bifoga kort',
  submit: 'Skicka',
  ascAboutShippingCost: 'Fråga leveranspriset',
  sendNotificationWhenYourBiddingIsWinning: 'Sänd påminnelse då mitt bud är vinnande',
  logOut: 'Logga ut',
  netvisorInvoice: 'Betala via Faktura',
  yourItemHsBeenCompensated: 'Dina sålda föremål har utbetalats till ditt konto',
  seller: 'Säljare',
  iAmSeller: 'Jag är säljare',
  accountNumber: 'Mitt kontonummer',
  proposals: 'prisförslag',
  approve: 'Godkänn',
  reject: 'Avvisa',
  approved: 'Godkännt',
  rejected: 'Avvisat',
  youWon: 'Du vann objektet! <0 /> Grattis!',
  youDidNotWin: 'Du vann inte detta objekt',
  liveAuctionStartsIn: 'Liveauktionen startar om',
  goToLobby: 'Gå till vänterummet',
  liveAuctionIsHappeningNow: 'Liveauktion pågår nu',
  goToLiveAuction: 'Gå till Liveauktionen',
  LiveAuctionWillStartIn: 'Live-auktinen startar om {{ min }}',
  withCommission: 'Inkl. provision',
  commission: 'Provision',
  winningPrice: 'Klubbat pris',
  totalPrice: 'Totalbelopp',
  totalSum: 'Totalsumma',
  products: 'Objekten',
  catalogue: 'Katalogen',
  netvisorShippingInvoice: 'Transportfaktura',
  lotNumber: 'Objekt nummer',
  checkout: 'Till kassan',
  participate: 'Delta',
  invoice: 'Faktura',
  passwordMustBeSame: 'Lösenorden måste matcha',
  passwordShouldBeMinNumbers: 'Lösenordet bör ha minst {{ min }} tecken',
  emailShouldBeUnique: 'Mailadressen är redan registrerad',
  invalidZipCode: 'Fel postnummer',
  start: 'Starta',
  basic: 'Grund',
  identification: 'Identifiering',
  dentificationHeading: 'Identifiering',
  ready: 'Färdig',
  next: 'Följande',
  attachPhotoDescription: 'Ladda upp en bild på ditt pass eller id-kort, så kan vi godkänna din identifiering',
  ifYouWantToSpeedUpText:
    'Om du vill snabba upp processen, så kan du verifiera ditt kreditkort.Dina personuppgifter är säkrade och sparas inte.  Ingen debitering görs på kortet',
  creditCardSpeedUpDesc: 'Jag vill snabba upp identifieringsprocessen och mata in mitt kreditkort.',
  noCardDesc: 'Jag vill inte uppge kreditkortsuppgifter',
  documentSent: 'Dokumentet skickat',
  documentSentDescription:
    'Vi har mottagit din begäran! Den behandlas inom 2-5 dagar. Om du vill ändra identifieringsmetod, kontakta: auctions@holmasto.fi',
  everythingIsReady: 'Allt är gjort!!',
  nowYouCanUseAllTheFeatures: 'Nu kan du använda alla egenskaper',
  toHomePage: 'TILL FÖRSTASIDAN',
  whenYouIdentifyDescription:
    'Då du identifierar dej via nätbank eller kreditkort kan du direkt använda alla egenskaper',
  whenYouIdentifyManualDescription:
    'Då du ber om manuell identifiering kan du inte direkt använda egenskaperna i auktionen. Väntetid 2-5 dagar',
  logInToTheOnlineStore: 'Logga in till nätbutiken',
  logInToTheOnlineAuction: 'Logga in till auktionen',
  youDontHaveBidsForThisAuction: 'Du har inga bud i denna auktion',
  generateLastAuctionPdf: 'Skriv ut senaste auktionslista',
  nextItem: 'Följande objekt',
  prevItem: 'Senaste objekt',
  requestShipping: 'Beställ transport',
  itemHasClosed: 'Objektet är stängt',
  sorry: 'Sorry (sv)',
  pageWasntFound: "Page wasn't found (sv)",
  GoToHomePage: 'Go to home page (sv)',
  hammerPrice: 'Klubbat pris',
  tokenExpired: 'Ogiltig länk',
  linkNotValid: 'Länken är inte längre giltig',
  youApprovedReception: 'Tack, du har godkänt vårt anbud!',
  weWillSendYouEmail: 'Försäljningskontraktet levereras via email.',
  youApprovedProducts: 'Tack, du har godkännt dina objekt att säljas på vår auktion.',
  youApprovedProductsDescription: `
    Vi rekommenderar att du loggar in/registrerar dig på Holmastos MyHolmasto, dina egna sidor. I MyHolmasto kan du acceptera/förkasta köperbjudanden och auktionsförsäljningskontrakt för dina föremål. 
    Du följer dina egna föremål i auktionen och får information om förbudsnivån och efter att auktionen avslutats har du en lista med preliminära auktionsresultat.
`,
  goToMyHolmasto: 'Gå till MyHolmasto',
  goToFrontPage: 'Gå till framsidan',
  emailWasChanged: 'E-postadressen uppdaterad',
  youHaveCanceledPayment: 'You have cancelled the payment (sv)',
  tokenWasExpiredTryAgain: 'Token was exzpired try again (sv)',
  passwordWasChanged: 'Password was changed (sv)',
  companyName: 'Företagsnamn',
  setPassword: 'Ange ett lösenord',
  begin: 'Börja',
  myProfile: 'Min profil',
  webshop: 'Nätbutik',
  auctionCatalogue: 'Auktionskatalog',
  youCanEnterAccountNumber: 'Här kan du ange ditt kontonummer om du väntar dig en transaktion från oss',
  priceWasRequested: 'Shipping price was requested (sv)',
  frontPage: 'Huvudsida',
  awaitingContactFromHolmasto: 'Awaiting contact from Holmasto (sv)',
  clickHereToRequestNewLink: 'Click <a>here</a> to request new link (sv)',
  pleaseFillBid: 'Please fill bid (sv)',
  bidShouldBeMoreThenAmount: 'Bid should be more then {{minimumBid}} € (sv)',
  canNotBeDelivered: 'Kan inte levereras',
  pending: 'Pending (sv)',
  processing: 'Processing (sv)',
  onHold: 'Om Hold (sv)',
  completed: 'Completed (sv)',
  cancelled: 'Cancelled (sv)',
  refunded: 'Refunded (sv)',
  failed: 'Failed (sv)',
  checkoutDraft: 'Checkout Draft (sv)',
  cookieSettings: 'COOKIE-INSTÄLLNINGAR',
  cookieDescription: `
    Vår webbplats använder cookies för att webbplatsen ska fungera korrekt och för att din användarupplevelse ska 
    bli ännu bättre. Du kan läsa mer om dem samt konfigurera dina inställningar
  `,
  necessary: 'NÖDVÄNDIG',
  statistics: 'STATISTIK',
  marketing: 'MARKNADSFÖRING',
  necessaryCookieDescription: `
    Dessa cookies är nödvändiga för att sidan skall fungera korrekt.
  `,
  statisticsCookieDescription: `
    För att förbättra vår hemsida samlar vi anonym information som är till för statistikföring och analyser. Med hjälp av 
    dessa cookies kan vi t.ex föja med hur många besökare sidan har eller hur länge de stannat på sidan. Detta hjälper oss 
    att skapa en bättre användarupplevelse.
  `,
  marketingCookieDescription: 'Dessa cookies hjälper oss att mäta och optimera vår marknadsföring.',
  approveAll: 'GODKÄNN ALLA',
  approveChosen: 'GODKÄNN VALDA',
  editCookies: 'REDIGERA COOKIES',
  yourPreBidIsWinning: 'Tarjouksesi ({{ amount }}€) on voittava',
  preBidHighestPrice: 'Högsta förbud',
  currentItems: 'Pågående objekten',
  upcomingAuction: 'Kommande auktion',
  localPickup: 'Hämtning från butiken',
})

export default sv
