import { Button } from 'components/Button'
import { Container } from 'components/Container'
import Section from 'components/Section/Section'
import { Heading, HeadingMargin, Paragraph } from 'components/Ui-Kits/Text'
import { missionURLs } from 'constants/index'
import {
  EXTRACT_HEADING_REGEX,
  EXTRACT_MISSION_LINK_REGEX,
  EXTRACT_MISSION_REGEX,
  EXTRACT_PARAGRAPHS_REGEX,
} from 'constants/regex'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { escape } from 'utils/escape'

export const Mission = () => {
  const {
    i18n: { language },
  } = useTranslation()
  const [mission, setMission] = useLocalStorage(`mission-${language}`, null)

  useEffect(() => {
    const controller = new AbortController()
    fetch(missionURLs[language], {
      method: 'GET',
      signal: controller.signal,
    })
      .then(result => result.json())
      .then(data => {
        const missionWrap = data?.content?.rendered?.match(EXTRACT_MISSION_REGEX)[1]
        if (!missionWrap) return setMission(null)

        const paragraphs = missionWrap
          .match(EXTRACT_PARAGRAPHS_REGEX)
          .map(str => str.replace(/<p>|<\/p>/g, ''))
          .filter(str => !!str.trim())
          .reduce((paragraphs, str) => [...paragraphs, ...str.split(/<br>/g).filter(str => !!str.trim())], [])
          .map(escape)

        const title = missionWrap.match(EXTRACT_HEADING_REGEX)[0].replace(/<h2[^>]*>|<\/h2>/g, '')
        const buttonStr = missionWrap.match(EXTRACT_MISSION_LINK_REGEX)?.[0] || ''
        const name = buttonStr.replace(/<a[^>]*>|<\/a>/g, '')
        const href = buttonStr.match(/(https?[^"']*?)["']/g, '')?.[0]?.replace('"', '')

        setMission({ title, paragraphs, button: { name, href } })
      })
      .catch(reason => {
        if (reason.name !== 'AbortError') {
          throw reason
        }
      })

    return () => {
      controller.abort()
    }
  }, [language, setMission])

  if (!mission) return null

  return (
    <Section background="var(--color-white)" align="left">
      <Container>
        {mission?.title && (
          <Heading color="primary" mt={HeadingMargin.NONE} mb={HeadingMargin.REGULAR}>
            {mission?.title}
          </Heading>
        )}
        {mission?.paragraphs &&
          mission?.paragraphs.map((paragraph, i) => (
            <Paragraph mb={1} key={i + ''}>
              {paragraph}
            </Paragraph>
          ))}
        {mission?.button?.name && (
          <Button color="primary" as="a" href={mission?.button?.href}>
            {mission?.button?.name}
          </Button>
        )}
      </Container>
    </Section>
  )
}
